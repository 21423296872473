import Layout from '@/page/index/'

export default [{
  path: '/wel',
  component: Layout,
  redirect: '/wel/index',
  children: [{
    path: 'index',
    name: '首页',
    meta: {
      i18n: 'dashboard'
    },
    component: () =>
      import( /* webpackChunkName: "views" */ '@/views/wel/index')
  }, {
    path: 'dashboard',
    name: '控制台',
    meta: {
      i18n: 'dashboard',
      menu: false,
    },
    component: () =>
      import( /* webpackChunkName: "views" */ '@/views/wel/dashboard')
  }]
}, {
  path: '/test',
  component: Layout,
  redirect: '/test/index',
  children: [{
    path: 'index',
    name: '测试页',
    meta: {
      i18n: 'test'
    },
    component: () =>
      import( /* webpackChunkName: "views" */ '@/views/util/test')
  }]
}, {
  path: '/dict-horizontal',
  component: Layout,
  redirect: '/dict-horizontal/index',
  children: [{
    path: 'index',
    name: '字典管理',
    meta: {
      i18n: 'dict'
    },
    component: () =>
      import( /* webpackChunkName: "views" */ '@/views/util/demo/dict-horizontal')
  }]
}, {
  path: '/dict-vertical',
  component: Layout,
  redirect: '/dict-vertical/index',
  children: [{
    path: 'index',
    name: '字典管理',
    meta: {
      i18n: 'dict'
    },
    component: () =>
      import( /* webpackChunkName: "views" */ '@/views/util/demo/dict-vertical')
  }]
}, {
  path: '/info',
  component: Layout,
  redirect: '/info/index',
  children: [{
    path: 'index',
    name: '个人信息',
    meta: {
      i18n: 'info'
    },
    component: () =>
      import( /* webpackChunkName: "views" */ '@/views/system/userinfo')
  }]
}, {
  path: '/work/process/leave',
  component: Layout,
  redirect: '/work/process/leave/form',
  children: [{
    path: 'form/:processDefinitionId',
    name: '请假流程',
    meta: {
      i18n: 'work'
    },
    component: () =>
      import( /* webpackChunkName: "views" */ '@/views/work/process/leave/form')
  }, {
    path: 'handle/:taskId/:processInstanceId/:businessId',
    name: '处理请假流程',
    meta: {
      i18n: 'work'
    },
    component: () =>
      import( /* webpackChunkName: "views" */ '@/views/work/process/leave/handle')
  }, {
    path: 'detail/:processInstanceId/:businessId',
    name: '请假流程详情',
    meta: {
      i18n: 'work'
    },
    component: () =>
      import( /* webpackChunkName: "views" */ '@/views/work/process/leave/detail')
  },
  // ,{
  //   path: '/pay',
  //   name: '支付项目',
  //   meta: {
  //     i18n: 'work'
  //   },
  //   component: () =>
  //     import( /* webpackChunkName: "views" */ '@/views/pay/payAction')
  // },
  // {
  //   path: '/pay',
  //   name: '支付项目',
  //   meta: {
  //     i18n: 'work'
  //   },
  //   component: () =>
  //     import( /* webpackChunkName: "views" */ '@/views/pay/payee')
  // },
  {
    path: '/pay',
    name: '支付看板',
    meta: {
      i18n: 'work'
    },
    component: () =>
        import( /* webpackChunkName: "views" */ '@/views/pay/paykanban')
  },{
    path: '/pay',
    name: '新增/编辑项目',
    meta: {
      i18n: 'work'
    },
    component: () =>
        import( /* webpackChunkName: "views" */ '@/views/pay/payActionAdd')
  },{
    path: '/pay',
    name: '接入与分享',
    meta: {
      i18n: 'work'
    },
    component: () =>
        import( /* webpackChunkName: "views" */ '@/views/pay/payShare')
  },{
    path: '/pay',
    name: '新增/编辑收款方',
    meta: {
      i18n: 'work'
    },
    component: () =>
        import( /* webpackChunkName: "views" */ '@/views/pay/payAddPayee')
  },{
    path: '/pay',
    name: '新增/编辑收款方',
    meta: {
      i18n: 'work'
    },
    component: () =>
        import( /* webpackChunkName: "views" */ '@/views/pay/payAddAccount')
  },{
    path: '/order',
    name: '支付订单',
    meta: {
      i18n: 'work'
    },
    component: () =>
        import( /* webpackChunkName: "views" */ '@/views/order/orderForPay')
  },{
    path: '/order/orderForDetail',
    name: 'orderForDetail',
    meta: {
      i18n: 'work'
    },
    component: () =>
    import( /* webpackChunkName: "views" */ '@/views/order/orderForDetail')
  },
  {
    path: '/order',
    name: '对账服务',
    meta: {
      i18n: 'work'
    },
    component: () =>
      import( /* webpackChunkName: "views" */ '@/views/order/orderForCheck')
  },{
    path: '/platform',
    name: '权限管理',
    meta: {
      i18n: 'work'
    },
    component: () =>
        import( /* webpackChunkName: "views" */ '@/views/platform/limitation')
  },{
    path: '/UserSide',
    name: '支付页',
    meta: {
      i18n: 'work'
    },
    component: () =>
        import( /* webpackChunkName: "views" */ '@/views/UserSide/PaymentPage')
  },{
    path: '/UserSide',
    name: '支付成功',
    meta: {
      i18n: 'work'
    },
    component: () =>
        import( /* webpackChunkName: "views" */ '@/views/UserSide/paySuccess')
  },{
    path: '/UserSide',
    name: '支付失败',
    meta: {
      i18n: 'work'
    },
    component: () =>
        import( /* webpackChunkName: "views" */ '@/views/UserSide/payFail')
  },
  {   // 支付资金池
    path: '/capitalPool/paymentPool',
    name: 'paymentPool',
    meta: {
      i18n: 'work'
    },
    component: () =>
        import( /* webpackChunkName: "views" */ '@/views/capitalPool/paymentPool')
  },{
    path: '/capitalPool/PoolBill',
    name: 'PoolBill',
    meta: {
      i18n: 'work'
    },
    component: () =>
        import( /* webpackChunkName: "views" */ '@/views/capitalPool/PoolBill')
  },{
    path: '/capitalPool/transferAccount',
    name: '人工转账',
    meta: {
      i18n: 'work'
    },
    component: () =>
        import( /* webpackChunkName: "views" */ '@/views/capitalPool/transferAccount')
  },
  {
    path: '/capitalPool/transferDetail',  // 人工转账详情页面
    name: 'transferDetail',
    meta: {
      i18n: 'work'
    },
    component: () =>
    import( /* webpackChunkName: "views" */ '@/views/capitalPool/transferDetail')
  },
  {
    path: '/capitalPool/payeeBill',
    name: 'payeeBill',
    meta: {
      i18n: 'work'
    },
    component: () =>
        import( /* webpackChunkName: "views" */ '@/views/capitalPool/payeeBill')
  },{
    path: '/wechat',
    name: '微信支付',
    meta: {
      i18n: 'work'
    },
    component: () =>
      import( /* webpackChunkName: "views" */ '@/views/wechat/WechatPay')
  },{
    path: '/wechat',
    name: '支付宝',
    meta: {
      i18n: 'work'
    },
    component: () =>
      import( /* webpackChunkName: "views" */ '@/views/wechat/AliPay')
  },{
    path: '/pay',
      name: '收款部门',
      meta: {
      i18n: 'work'
    },
    component: () =>
    import( /* webpackChunkName: "views" */ '@/views/pay/payer')
  },{ // 收款部门--开通权限
    path: '/pay/deptPower',
    name: 'deptPower',
    meta: {
      i18n: 'work'
    },
    component: () =>
    import( /* webpackChunkName: "views" */ '@/views/pay/deptPower')
  },
  { // 收款部门--开通权限
    path: '/pay/addPower',
    name: 'addPower',
    meta: {
      i18n: 'work'
    },
    component: () =>
    import( /* webpackChunkName: "views" */ '@/views/pay/addPower')
  },
    { // 当面支付项目管理
      path: '/payInPerson/list',
      name: '当面付管理',
      meta: {
        i18n: 'work'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/payInPerson/list')
    },
    { // 新增当面付用户
      path: '/payInPerson/AddUser',
      name: '新增操作用户',
      meta: {
        i18n: 'work'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/payInPerson/AddUser')
    },
    { // 新增权限管理页面
      path: '/power/power',
      name: '权限管理',
      meta: {
        i18n: 'work'
      },
      component: () =>
        import( /* webpackChunkName: "views" */ '@/views/power/power')
    }]
}]
