<template>
    <div class="headWarp">
        <div class="head-top">
            <img class="gwbLogo" src="../img/gwbLogo.png" alt="">
            <span class="line"></span>
            <img class="logo" src="../img/logo.png" alt="">
            <span class="line"></span>
            <!-- <span>中天钢铁集团统一收款平台</span> -->
            <span>智慧金融/支付服务平台</span>
        </div>
        <div class="head-info">
            <div><img class="infoImg" src="../img/info.png" alt=""></div>
            <div>
                <p>你好，{{userInfo.user_name}}</p>
                <p class="span_link">
                    <span @click="gotoInfo('password')">修改密码</span>
                    <span @click="gotoInfo('info')">客户中心</span>
                    <span @click="logout">退出</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {resetRouter} from '@/router/router'
export default {
    name: 'headerCom',
    computed: {
        ...mapGetters([
        "userInfo"
      ])
    },
    methods: {
      logout() {
            // alert('--')
        this.$confirm(this.$t("logoutTip"), this.$t("tip"), {
          confirmButtonText: this.$t("submitText"),
          cancelButtonText: this.$t("cancelText"),
          type: "warning"
        }).then(() => {
          this.$store.dispatch("LogOut").then(() => {
            resetRouter();
            this.$router.push({path: "/login"});
          });
        });
      },
      gotoInfo(type){
        this.$router.push({path: "/info/index", query:{userType:type}});
      }
    }
}
</script>
<style scoped lang="scss">
p{ margin: 0; padding: 0;}
.headWarp{
    display: flex;
    // justify-items: center;
    // align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    .head-info{
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        .infoImg{
            width: 32px; height: 32px;
            // float: left;
            margin-right: 10px;
        }
    }
    .span_link{
        font-size: 14px;
        color: #666;
        line-height: 24px;
        span{
            cursor: pointer;
            margin-right: 10px;
        }
    }
}
    .head-top{
      height: 80px;
      display: flex;
      justify-items: center;
      align-items: center;
      font-size: 18px;
      color: #000000;
      width: auto;
      .logo{
        width: 161px;
        height: 42px;
        // margin-left: 37px;
        margin-right: 15px;
        margin-left: 15px;
      }
       .gwbLogo{
        width: 130px;
        height: 40px;
        margin-left: 37px;
        margin-right: 15px;
      }
      .line{
        border-left: solid 1px rgb(206, 201, 201); height: 28px; margin-right: 15px;
      }
    }
</style>