import Vue from 'vue';
import axios from './router/axios';
import VueAxios from 'axios';
import Vuex from 'vuex'


import App from './App';
import router from './router/router';
import './permission'; // 权限
import './error'; // 日志
import './cache';//页面缓存
import store from './store';
import {loadStyle} from './util/util'
import * as urls from '@/config/env';
import ElementUI from 'element-ui';
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
import {
  iconfontUrl,
  iconfontVersion
} from '@/config/env';
import i18n from './lang' // Internationalization
import './styles/common.scss';
import basicBlock from './components/basic-block/main'
import basicContainer from './components/basic-container/main'
import thirdRegister from './components/third-register/main'
import avueUeditor from 'avue-plugin-ueditor';
import website from '@/config/website';

Vue.use(router);
// Vue.use(axios);
Vue.use(Vuex)
Vue.use(ElementUI)
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});
Vue.use(Avue);
Vue.use(window.AVUE, {
  size: 'small',
  tableSize: 'small',
  i18n: (key, value) => i18n.t(key, value)
});
Vue.prototype.$axios = VueAxios
window.axios = VueAxios
//注册全局容器
Vue.component('basicContainer', basicContainer);
Vue.component('basicBlock', basicBlock);
Vue.component('thirdRegister', thirdRegister);
Vue.component('avueUeditor', avueUeditor);
// 加载相关url地址
Object.keys(urls).forEach(key => {
  Vue.prototype[key] = urls[key];
});
// 加载website
Vue.prototype.website = website;
// 动态加载阿里云字体库
iconfontVersion.forEach(ele => {
  loadStyle(iconfontUrl.replace('$key', ele));
});

Vue.config.productionTip = false;
// Vue.prototype.$axios = axios
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
