import Layout from '@/page/index/'

export default [{
  path: '/login',
  name: '登录页',
  component: () =>
    import( /* webpackChunkName: "page" */ '@/page/login/index'),
  meta: {
    keepAlive: true,
    isTab: false,
    isAuth: false
  }
},
  {
    path: '/lock',
    name: '锁屏页',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/lock/index'),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/404',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/components/error-page/404'),
    name: '404',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }

  },
  {
    path: '/403',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/components/error-page/403'),
    name: '403',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
  path: '/500',
    component: () =>
    import( /* webpackChunkName: "page" */ '@/components/error-page/500'),
    name: '500',
    meta: {
    keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
{
  path: '/GoToPay',
    component: () =>
  import( /* webpackChunkName: "page" */ '@/page/ztpay/GoToPay'),
    name: '支付页',
  meta: {
  keepAlive: true,
    isTab: false,
    isAuth: false
}
},
  {
    path: '/GoToPay_D',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/ztpay/GoToPay_D'),
    name: '党费-支付页',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
{
  path: '/SuccessPay',
    component: () =>
  import( /* webpackChunkName: "page" */ '@/page/ztpay/SuccessPay'),
    name: '支付结果',
  meta: {
  keepAlive: true,
    isTab: false,
    isAuth: false
}
},
  {
    path: '/GoToPay2',
    component: () =>
        import( /* webpackChunkName: "page" */ '@/page/ztpay/GoToPay2'),
    name: '违章支付',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/',
    name: '主页',
    redirect: '/wel/index'
  },
  // {
  //   path: '/',
  //   name: '首页',
  //   redirect: '/pay/paykanban'
  // },
  {
    path: '/myiframe',
    component: Layout,
    redirect: '/myiframe',
    children: [{
      path: ":routerPath",
      name: 'iframe',
      component: () =>
        import( /* webpackChunkName: "page" */ '@/components/iframe/main'),
      props: true
    }]

  },
  {
    path: '*',
    redirect: '/404'
  }
]
